<template>
  <div class="vehicle-monitoring-container">
    <!-- 地图 -->
    <!-- <div id="vehicleContainer"></div> -->
    <iframe :src="url" frameborder="0"  width="100%" height="100%"></iframe>
    <!-- 搜索 -->
    <div class="search">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <!-- <el-form-item label="车牌号">
          <el-switch v-model="dataForm.vehiclePlate" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </el-form-item> -->
        <el-form-item label="刷新频率" prop="refreshRate">
          <el-select class="item-choose" v-model="dataForm.refreshRate" style="width: 100%;" @change="setRefreshTime(true)">
            <el-option label="不刷新" value="-1"></el-option>
            <el-option label="15秒" value="15"></el-option>
            <el-option label="30秒" value="30"></el-option>
            <el-option label="1分钟" value="60"></el-option>
            <el-option label="5分钟" value="300"></el-option>
            <el-option label="10分钟" value="600"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div id="outer-box">
      <div id="panel" :style="{'left': (-offsetWid + 10) + 'px'}">
        <span class="showDetail" @click="showDetail" :style="{'right': (- 20) + 'px'}">
          <img src="@/../static/img/show.png" alt="" class="rotateDeg" v-if="offsetWid == 0">
          <img src="@/../static/img/show.png" alt="" v-else>
        </span>
        <div class="panelTitle">
          车辆列表
        </div>
        <div class="vehicle-search">
          <el-input v-model="search.vehiclePlate" placeholder="请输入车牌号">
             <el-button slot="append" icon="el-icon-search" @click="getDataList"></el-button>
          </el-input>
        </div>
        <div class="my-list">
           <el-tabs v-model="activeName" type="card" @tab-click="handleClick">

            <el-tab-pane label="全部" name="first">
              <ul class="th">
                <li>车牌号</li>
                <li>状态</li>
                <li>速度</li>
              </ul>
              <div class="outer-container">
                <div class="inner-container">
                  <div class="content">
                    <ul v-for="(item, index) in dataList" :key="index" @click="getNewlocation(item)">
                      <li :title="item.vehiclePlate">{{item.vehiclePlate}}</li>
                      <li>
                        <span v-if="item.status == 1">
                          <el-tag type="success">行驶中</el-tag>
                        </span>
                        <span v-else>
                          <el-tag type="info">静止</el-tag>
                        </span>
                      </li>
                      <li>{{ item.speed }} km/h</li>
                    </ul>
                  </div>
                </div>
              </div>
            </el-tab-pane>

<!--            <el-tab-pane label="在线" name="second">-->
<!--              <ul class="th">-->
<!--                <li>车牌号</li>-->
<!--                <li>车辆状态</li>-->
<!--                <li>时长或速度</li>-->
<!--              </ul>-->
<!--              <div class="outer-container">-->
<!--                <div class="inner-container">-->
<!--                  <div class="content">-->
<!--                    <ul v-for="(item, index) in dataList" :key="index" @click="getNewlocation(item)">-->
<!--                      <li>{{item.vehiclePlate}}</li>-->
<!--                      <li>-->
<!--                        <span v-if="item.status == 1">-->
<!--                          <el-tag type="success">行驶中</el-tag>-->
<!--                        </span>-->
<!--                        <span v-else-if="item.status == 2">-->
<!--                          <el-tag type="danger">离线</el-tag>-->
<!--                        </span>-->
<!--                        <span v-else-if="item.status == 3">-->
<!--                          <el-tag type="info">静止</el-tag>-->
<!--                        </span>-->
<!--                      </li>-->
<!--                      <li>88km/h</li>-->
<!--                    </ul>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </el-tab-pane>-->

<!--            <el-tab-pane label="离线" name="third">-->
<!--              <ul class="th">-->
<!--                <li>车牌号</li>-->
<!--                <li>车辆状态</li>-->
<!--                <li>时长或速度</li>-->
<!--              </ul>-->
<!--              <div class="outer-container">-->
<!--                <div class="inner-container">-->
<!--                  <div class="content">-->
<!--                     <ul v-for="(item, index) in dataList" :key="index" @click="getNewlocation(item)">-->
<!--                      <li>{{item.vehiclePlate}}</li>-->
<!--                      <li>-->
<!--                        <span v-if="item.status == 1">-->
<!--                          <el-tag type="success">行驶中</el-tag>-->
<!--                        </span>-->
<!--                        <span v-else-if="item.status == 2">-->
<!--                          <el-tag type="danger">离线</el-tag>-->
<!--                        </span>-->
<!--                        <span v-else-if="item.status == 3">-->
<!--                          <el-tag type="info">静止</el-tag>-->
<!--                        </span>-->
<!--                      </li>-->
<!--                      <li>88km/h</li>-->
<!--                    </ul>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </el-tab-pane>-->
          </el-tabs>
        </div>
      </div>
    </div>
    <iframe-track ref="track"></iframe-track>
  </div>
</template>

<script>
import iframeTrack from '@/views/commonPop/iframe-track'
export default {
  components: {
    iframeTrack
  },
  data () {
    return {
      mapInfo: '', // 地图
      dataForm: {
        refreshRate: '15', // 刷新频率
        vehiclePlate: ''
      },
      search: {
        vehiclePlate: ''
      },
      selectedInfo: {
        dataList: [],
        position: []
      },
      dataList: [], // 列表数据
      offsetWid: '0', // 隐藏地图详情面板时位移值
      activeName: 'first',
      markers: [],
      url: '',
      status: 1,
      timer: ''
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  created () {
  },
  destroyed () {
    // 页签关闭，销毁定时器
    clearTimeout(this.timer)
  },
  methods: {
    // 得到新的位置信息
    getNewlocation (row) {
      this.$http({
        url: this.$http.adornUrl(`/vehicleInfo/getToMapNewLocationUrl/${row.vehiclePlate}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        // window.open(data)
        this.$refs.track.init(data)
      }).catch(() => {})
    },
    showDetail () {
      if (this.offsetWid == 0) {
        this.offsetWid = 220
      } else {
        this.offsetWid = 0
      }
    },
    initMap () {
      this.$http({
        url: this.$http.adornUrl('/vehicleInfo/getToMapNewLocationUrl'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.url = data
        this.dataListLoading = false
      }).catch(() => {})
    },
    // 初始化
    getDataList () {
      // 地图刷新
      this.dataListLoading = true
      // 列表刷新
      this.$http({
        url: this.$http.adornUrl('/vehicleInfo/getGpsVehicleList'),
        method: 'get',
        params: this.$http.adornParams({
          'vehiclePlate': this.search.vehiclePlate
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data
          // this.totalPage = data.total
        } else {
          this.dataList = []
          // this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 刷新频率
    setRefreshTime (reset) {
      let refreshRate = Number(this.dataForm.refreshRate)
      if (refreshRate === -1) {
        clearTimeout(this.timer)
        return
      }
      // 是否重置定时器
      if (reset) {
        clearTimeout(this.timer)
      }
      let _this = this
      this.timer = setInterval(function () {
        _this.initMap()
      }, refreshRate * 1000)
    },
    // 点击车辆列表tab
    handleClick(tab, event) {
      if (this.activeName == 'first') {
        this.status = 1
      } else if (this.activeName == 'second') {
        this.status = 2
      } else {
        this.status = 3
      }
    }
  },
  mounted () {
    this.getDataList()
    this.initMap()
    this.setRefreshTime(true)
  }
}
</script>
  <style lang="less">
  .vehicle-monitoring-container {
    margin: -20px;
    position: relative;
    height: 86vh;
    .search {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translate(-50%);
      padding: 5px 20px;
      border: 1px solid #ccc;
      background: #fff;
      transform: translate(-50%, 50%);
      min-width: 300px;
      .el-input {
        width: 113px;
      }
      .el-form-item {
        margin-bottom: 0;
      }
    }
    #vehicleContainer {
      width: 100%;
      height: 87vh;
    }
    #outer-box {
      .el-tabs__nav {
        border-radius: 0;
      }
    }
    #panel {
      width: 220px;
      background: #fff;
      position: absolute;
      top: 10px;
      left: 10px;
      height: 99%;
      // overflow: auto;
      transition: left 2s;
      -moz-transition: left 2s; /* Firefox 4 */
      -webkit-transition: left 2s; /* Safari and Chrome */
      -o-transition: left 2s; /* Opera */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      .showDetail {
        position: absolute;
        top: 40%;
        right: -20px;
        z-index: 1;
        background: #45c2b5;
        display: block;
        width: 20px;
        height: 100px;
        line-height: 100px;
        text-align: left;
        border-radius: 0 5px 5px 0;
        color: #fff;
        cursor: pointer;
        transition: right 2s;
        -moz-transition: right 2s; /* Firefox 4 */
        -webkit-transition: right 2s; /* Safari and Chrome */
        -o-transition: right 2s; /* Opera */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
        .rotateDeg {
          -webkit-transform:rotate(180deg);
          transform:rotate(180deg);
          -webkit-transition:-webkit-transform 2s linear;
          transition:transform 2s linear;
        }
        img {
          width: 100%;
          height: 30px
        }
      }
      .panelTitle {
        height: 32px;
        background: #45c2b5;
        color: #fff;
        line-height: 32px;
        font-weight: 700;
        padding-left: 20px;
      }
      .vehicle-search {
        .el-input__inner {
          border-radius: 0;
        }
        .el-input-group__append {
          border-radius: 0;
        }
      }
      .my-list {
        height: calc(100% - 32px - 36px);
        .el-tabs {
          height: 100%;
          .el-tabs__header {
            margin-bottom: 0;
            height: 32px;
            .el-tabs__item {
              height: 32px;
              line-height: 32px;
            }
          }
          .el-tabs__nav {
            border-top:0
          }
          .el-tabs__content {
            height: calc(100% - 32px);
            .el-tab-pane {
              height: 100%;
              ul {
                list-style: none;
                padding: 0px;
                margin: 0px;
                width: 100%;
                height: 26px;
                line-height: 26px;
                cursor: pointer;
                border: 1px solid #e2e2e2;
                li{
                  display:block;
                  width:33%;
                  float:left;
                  // text-indent:2em
                  text-align: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
              .th{
                background: #f2f2f2;
                font-weight: bold;
                border-top: 1px;
              }
              .outer-container,.content {
                height: calc(100% - 32px);
                width: 100%;
                font-size: 12px;
                ul {
                  border-top: 0;
                }
                ul:hover {
                  background: rgb(250, 195, 100)
                }
              }
              .outer-container {
                  position: relative;
                  overflow: hidden;
              }
              .inner-container {
                  position: absolute; left: 0;
                  overflow-x: hidden;
                  overflow-y: scroll;
                  height: 100%;
                  width: 100%;
              }
              /* for Chrome */
              .inner-container::-webkit-scrollbar {
                  display: none;
              }
            }
          }
        }
      }
    }
    .info-map-content {
      display: flex;
      flex-direction: row;
      .info-map-row {
        // display: flex;
        .info-map-box {
          width: 350px;
          font-size: 12px;
          .info-map-title {
            text-align: center;
            height: 22px;
            line-height: 22px;
            display: flex;
            font-weight: 600;
            span.gradual-left, span.gradual-right {
              display: inline-block;
              width: 50px;
              background-color: #3e86f1;
              height: 4px;
              line-height: 4px;
              flex: 2;
              justify-content: center;
              align-items: center;
              float: left;
              margin: 8px 10px;
            }
            span.gradual-left {
              background-image: linear-gradient(to left, #3e86f1, #fff);
            }
            span.gradual-right {
              background-image: linear-gradient(to right, #3e86f1, #fff);
            }
          }
          .info-map-body {
            background: #f2f2f2;
            padding: 5px;
            .rows {
              height: 20px;
              line-height: 20px;
              display: flex;
              .flex-1 {
                flex: 1;
                .label {
                  display: inline-block;
                  width: 88px;
                  text-align: right;
                }
                .value {
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
      .info-map-row-1 {
         .info-map-box {
          width: 450px !important;
          .label {
            width: auto !important;
          }
        }
      }
    }
  }
</style>
